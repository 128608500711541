<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div class="container-profile">
            <p class="text-title mon-bold">{{ texts.profile.textTitle }}</p>
            <template>
              <v-tabs
                v-model="tab"
                show-arrows
                background-color="transparent"
              >
                <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
                <v-tab
                  @click="tab1"
                  class="tabs-profile mon-regular"
                >{{ texts.profile.textsTabs.textUser }}</v-tab>
                <v-tab
                  @click="tab2"
                  class="tabs-profile mon-regular"
                >{{ texts.profile.textsTabs.textEnterprise }}</v-tab>
                <v-tab
                  @click="tab3"
                  class="tabs-profile mon-regular"
                >{{ texts.profile.textsTabs.textAttachments }}</v-tab>
                <v-tab
                  v-if="this.$store.state.isManager"
                  @click="tab4"
                  class="tabs-profile mon-regular"
                >{{ texts.profile.textsTabs.textBuyers }}</v-tab>
              </v-tabs>

              <v-tabs-items :touchless="true" v-model="tab">
                <v-tab-item>
                  <profile-tab-content-one-layout :texts="texts"></profile-tab-content-one-layout>
                </v-tab-item>
                <v-tab-item>
                  <profile-tab-content-two-layout
                    :texts="texts"
                    :tab="tab"
                  ></profile-tab-content-two-layout>
                </v-tab-item>
                <v-tab-item>
                  <profile-tab-content-three-layout :texts="texts"></profile-tab-content-three-layout>
                </v-tab-item>
                <v-tab-item>
                  <profile-tab-content-four-layout :texts="texts"></profile-tab-content-four-layout>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ProfileContentLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      tab: null,
    };
  },
  beforeMount() {
    if (this.$route.path == "/profile/tab/1") {
      this.tab = 0;
    } else if (this.$route.path == "/profile/tab/2") {
      this.tab = 1;
    } else if (this.$route.path == "/profile/tab/3") {
      this.tab = 2;
    } else if (this.$route.path == "/profile/tab/4") {
      this.tab = 3;
    }
  },
  methods: {
    tab1: function () {
      this.$router
        .push({
          path: "/profile/tab/1",
        })
        .catch(() => {});
    },
    tab2: function () {
      this.$router
        .push({
          path: "/profile/tab/2",
        })
        .catch(() => {});
    },
    tab3: function () {
      this.$router
        .push({
          path: "/profile/tab/3",
        })
        .catch(() => {});
    },
    tab4: function () {
      this.$router
        .push({
          path: "/profile/tab/4",
        })
        .catch(() => {});
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == "/profile/tab/1") {
        this.tab = 0;
      } else if (to.path == "/profile/tab/2") {
        this.tab = 1;
      } else if (to.path == "/profile/tab/3") {
        this.tab = 2;
      } else if (to.path == "/profile/tab/4") {
        this.tab = 3;
      }
    },
  },
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>

<style scoped>
.separator-line-tab {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #3d414a !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}
</style>